import { FC } from "react";
import { Carousel, Image } from 'antd';

// import sl_1_1 from "../../images/slider/1_1.jpg";
// import sl_1_2 from "../../images/slider/1_2.jpg";
// import sl_1_3 from "../../images/slider/1_3.jpg";
// import sl_2_1 from "../../images/slider/2_1.jpg";
// import sl_2_2 from "../../images/slider/2_2.jpg";
// import sl_2_3 from "../../images/slider/2_3.jpg";

import sl_1 from "../../images/slider/1.jpg";
import sl_2 from "../../images/slider/2.jpg";
import sl_3 from "../../images/slider/3.jpg";
import sl_4 from "../../images/slider/4.jpg";

import './Slider.css';

const Slider: FC = () => (
  <Carousel
    effect="fade"
    autoplay
    autoplaySpeed={5000}
    draggable={true}
  >
    <div>
      {/*<div className="slide">*/}
      {/*  <Image src={ sl_1_1 } alt="Дизайн" preview={false}/>*/}
      {/*  <Image src={ sl_1_2 } alt="Дизайн" preview={false}/>*/}
      {/*  <Image src={ sl_1_3 } alt="Дизайн" preview={false}/>*/}
      {/*</div>*/}
        <Image src={ sl_1 } alt="Наружная реклама" preview={false}/>
    </div>
    <div>
      {/*<div className="slide">*/}
      {/*  <Image src={ sl_2_1 } alt="Полиграфия" preview={false}/>*/}
      {/*  <Image src={ sl_2_2 } alt="Полиграфия" preview={false}/>*/}
      {/*  <Image src={ sl_2_3 } alt="Полиграфия" preview={false}/>*/}
      {/*</div>*/}
      <Image src={ sl_2 } alt="Полиграфия" preview={false}/>
    </div>
    <div>
      <Image src={ sl_3 } alt="Сувенирная продукция" preview={false}/>
    </div>
    <div>
      <Image src={ sl_4 } alt="Календари" preview={false}/>
    </div>
  </Carousel>
);

export default Slider;